import styled from "styled-components";
import {Operation} from "@mbj-front-monorepo/data-access";
import {CompetenceCompareCard} from "../02_Externe/components/CompetenceCompareCard";

interface CompetenceDetailsCtrlProps {
  className?:string;
  List?: Operation<"getProximiteReferentielItem">["Shared" | "Missed" | "ToRised"];
  libelle:string;
  mainColor:string;
}

const CompetenceDetailsCtrl = ({className, List, libelle}: CompetenceDetailsCtrlProps) => {
  return (
    <div className={`comp_details ${className}`}>
      <div className="title">{libelle}</div>
      <div className="list_content">
        {List?.map((item)=>(
          <CompetenceCompareCard libelle={item.libelle||''} nivo={item.niveauActuel||0} nivoA={item.niveauAttendu||0}/>
        ))}
      </div>
    </div>
  )
}

export const CompetenceDetails = styled(CompetenceDetailsCtrl)`
  padding: 1rem;
  .title{
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
  }
`
