import styled from "styled-components";
import React, {useState} from "react";
import {GetElementType, useDebounce} from "@mbj-front-monorepo/utils";
import {useSearchActivites} from "@mbj-front-monorepo/data-access";
import {MbjListItem, MbjLoading, MbjScrollArea, MbjSearchBar, MbjSimpleInputContainer} from "@mbj-front-monorepo/ui";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";

interface SearchActivitesCtrlProps{
    className?:string;
}

const SearchActivitesCtrl = ({className}:SearchActivitesCtrlProps)=>{
    const [search, setSearch] = useState("");
    const [actiNiveau, setActiNiveau] = useState(0);
    const debouncedSearch = useDebounce(search, 500);
    const { isLoading, isError, data: activites } = useSearchActivites(debouncedSearch);
    const navigate = useNavigate();
    const {
        register,
        formState: { errors },
    } = useForm();
    const ClickOnActi = (acti:GetElementType<typeof activites>)=>{
        const Competence = acti.Competence;
        if(Competence){
            const Famille = Competence.Famille;
            if(Famille){
                const Domaine = Famille.Domaine;
                if(Domaine){
                    if(Domaine.id && Famille.id && Competence.id && acti.id)
                    navigate(""+Domaine.id+"/"+Famille.id+"/"+Competence.id)
                }
            }
        }
    }
    return (
        <div className={`search_acti ${className}`}>
            <div className={"zoneSearch"} style={{paddingTop:"24px"}}>
                <MbjSimpleInputContainer errors={errors} direction={"column"}>
                    <MbjSearchBar
                        id={"sarchComp"}
                        placeholder={"Rechercher une compétence"}
                        className={"SearchBar"}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                </MbjSimpleInputContainer>
                {search !== '' &&
                    <div className="resultats">
                        {search === "" ? (
                            <React.Fragment />
                        ) : isLoading ? (
                            <MbjLoading />
                        ) : isError ? (
                            "Errors"
                        ) : activites.length === 0 ? (
                            <p className="noResult">Aucun résultat trouvé pour "{search}"</p>
                        ) : (
                            <>
                                <MbjScrollArea maxHeight={"300px"}>
                                    {activites?.map((activite, idx: number) => (
                                        <MbjListItem className="one-choice-activite" onClick={() => ClickOnActi(activite)} key={idx}>
                                            {activite.libelle}
                                        </MbjListItem>
                                    ))}
                                </MbjScrollArea>
                            </>
                        )}
                    </div>
                    }
            </div>
        </div>
    )
}

export const SearchActivites = styled(SearchActivitesCtrl)`
  margin-bottom: 20px;
  .zoneSearch{
    position: relative;
  }
  .resultats{
    position: absolute;
    left: 0;
    top: 100%;
    background: white;
    padding: 0.2rem;
    z-index: 2;
    width: 100%;
    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
  }
`
