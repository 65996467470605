import styled from "styled-components";
import {
  useGetFormationToMetier,
  useGetMetier,
  useGetMetrixMetier, useGetMetrixMetierEvaluate,
  useGetProximiteReferentiel
} from "@mbj-front-monorepo/data-access";
import {MbjCardClassic, MbjCirclePercent, MbjLoading, MbjNoResult, MbjSquareChart} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {useState} from "react";
import {FiChevronDown} from "react-icons/fi";
import {CompetenceDetails} from "../../components/CompetenceDetails";
import {LineFormationMetier} from "./LineFormationMetier";
import {LineFormationCourte} from "./LineFormationCourte";

interface CompareToMetierPartCtrlProps {
  className?:string;
  idMetier:number;
  idPart:number;
}

const CompareToMetierPartCtrl = ({className, idMetier, idPart}: CompareToMetierPartCtrlProps) => {
  const proximiteReferentielQuery = useGetProximiteReferentiel('PM', idPart, idMetier);
  const metrixQuery = useGetMetrixMetierEvaluate(idMetier, idPart);
  const MetierQuery = useGetMetier(idMetier);
  const [openDetails, setOpenDetails] = useState(false);

  const totalComp: number =
    (proximiteReferentielQuery.data?.Shared?.length || 0) +
    (proximiteReferentielQuery.data?.Missed?.length || 0) +
    (proximiteReferentielQuery.data?.ToRised?.length || 0);
  return (
    <div className={`compare_emp_metier ${className}`}>
      {proximiteReferentielQuery.isLoading || MetierQuery.isLoading || metrixQuery.isLoading ?
      <MbjLoading/>:
        <>
          <h1 style={{padding:"0.5rem", textAlign:"center"}}>Comparaison au métier {MetierQuery.data?.libelle||'nc'}</h1>
          <MbjCirclePercent
            percent={
              proximiteReferentielQuery.data?.score ? Math.round(proximiteReferentielQuery.data?.score * 100) : 0
            }
          />
          <div className="chartsContainer">
            <MbjSquareChart
              number={proximiteReferentielQuery.data?.Shared?.length || 0}
              total={totalComp}
              legend={"compétences partagées"}
              color={"#85c172"}
            />

            <MbjSquareChart
              number={proximiteReferentielQuery.data?.ToRised?.length || 0}
              total={totalComp}
              legend={"compétences à améliorer"}
              color={"#eeac6b"}
            />
            <MbjSquareChart
              number={proximiteReferentielQuery.data?.Missed?.length || 0}
              total={totalComp}
              legend={"compétences à acquérir"}
              color={"#ee6b7d"}
            />
          </div>
          <div className={`wrap_details`}>
            <div className={`switch_details ${openDetails ? 'opened' : 'closed'}`}
                 onClick={() => setOpenDetails(o => !o)}>
              <div className="wrap_svg_switch">
                <FiChevronDown/>
              </div>
              <span>Afficher le détail</span>
              <div className="wrap_svg_switch">
                <FiChevronDown/>
              </div>
            </div>
            {openDetails &&
              <div className="content_details">
                <CompetenceDetails mainColor={"#b4eca4"} libelle={"Partagées"}
                                   List={proximiteReferentielQuery.data?.Shared}/>
                <CompetenceDetails mainColor={"#b4eca4"} libelle={"A améliorer"}
                                   List={proximiteReferentielQuery.data?.ToRised}/>
                <CompetenceDetails mainColor={"#b4eca4"} libelle={"A acquérir"}
                                   List={proximiteReferentielQuery.data?.Missed}/>
              </div>
            }
            <div className="wrapper_infos">
              <h2>Informations sur le métier</h2>
              <div className={"one_info_metier"}>
                <div className="titre_infos">Salaire</div>
                <div className="Info">
                  <div className="in_infos">
                    {metrixQuery.data?.salaire}€
                  </div>
                </div>
              </div>
              <div className={"one_info_metier"}>
                <div className="titre_infos">Code Rome</div>
                <div className="Info">
                  <div className="in_infos">
                    {MetierQuery.data?.codeRome}
                  </div>
                </div>
              </div>
              <div className={"one_info_metier"}>
                <div className="titre_infos">Champ professionnel</div>
                <div className="Info">
                  <div className="in_infos">
                    {MetierQuery.data?.champPro}
                  </div>
                </div>
              </div>
              <div className={"one_info_metier"}>
                <div className="titre_infos">Fonction</div>
                <div className="Info">
                  <div className="in_infos">
                    {MetierQuery.data?.fonctions}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export const CompareToMetierPart = styled(CompareToMetierPartCtrl)`


  .chartsContainer {
    margin: 15px 0;
    padding: 0 0.5rem;
  }
  .wrapper_infos{
    margin: 15px auto;
    width: calc(100% - 2rem);
    h2{
      font-size: 16px;
      color: ${props => props.theme.primary};
    }
  }
  .one_info_metier{
    background: ${props=>props.theme.greyPastel};
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    .titre_infos{
      padding: 0.25rem 0.75rem;
      font-weight: bold;
      font-size: 15px;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .Info{
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .in_infos{
        margin: auto;
        text-align: center;
        font-size: 16px;
      }
    }
  }

  .wrap_svg_switch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: rotate(0);
    transition: transform 0.3s;
  }

  .switch_details {
    width: 95%;
    padding: 0.5rem 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    gap: 5px;
    margin: auto;
    align-items: center;
    background: ${props => props.theme.secondaryLighten};
    &.opened{
      .wrap_svg_switch{
        transform: rotate(180deg);
        transition: transform 0.3s;
      }
    }
  }
`
