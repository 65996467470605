import styled from "styled-components";
import {HeaderMobileSwitchMenu, MbjMobileMenuSwitch} from "@mbj-front-monorepo/ui";
import {Outlet, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

interface LayoutCvCtrlProps {
  className?:string;
}

const Menus:MbjMobileMenuSwitch[] = [
  {libelle:"Expérience", to:'xp'},
  {libelle:"Formation", to:'fi'},
]
const LayoutCvCtrl = (props: LayoutCvCtrlProps) => {
  const location = useLocation()
  const [Current, setCurrent] = useState('')
  useEffect(() => {
    const pathname = location.pathname;
    const TabP = pathname.split('/')
    console.log(TabP[3]);
    setCurrent(TabP[3] === 'fi' ? 'Formation' : 'Expérience')
  }, [location]);
  return (
    <div className={`LayoutsCV ${props.className}`}>
      <HeaderMobileSwitchMenu Menus={Menus} Current={Current}/>
      <div className="contentCV">
        <Outlet/>
      </div>
    </div>
  )
}

export const LayoutCv = styled(LayoutCvCtrl)`
  display: flex;
  flex-direction: column;
  height: 100%;
  .contentCV{
    height: 100%;
  }
`
