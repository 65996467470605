import {Navigate, Route, Routes} from "react-router-dom";
import {Dashboard} from "../pages/Dashboard/Dashboard";
import {NotFound} from "../pages/NotFound/NotFound";
import {
  DesktopLayout,
  MainDesktopLayout,
  MainMobileLayout,
  MbjBlockMenu,
  MbjLoading,
  MbjMenuLinks,MobileLayout
} from "@mbj-front-monorepo/ui";
import {MdAccountCircle, MdExplore} from "react-icons/md";
import {GiSkills} from "react-icons/gi";
import {BsChatSquareText, BsFillBriefcaseFill} from "react-icons/bs";
import React, {useEffect} from "react";
import {useWindowSize} from "@mbj-front-monorepo/utils";
import {GetListAuthOnglet, logout, useGetMemberMe} from "@mbj-front-monorepo/data-access";
import {FaUser, FaUserCircle} from "react-icons/fa";
import {app_version, queryClient} from "./App";
import {IoHome} from "react-icons/io5";
import {TiFlowSwitch} from "react-icons/ti";
import {CvRooter} from "../pages/CV/CvRooter";
import {DashRooter} from "../pages/Dashboard/DashRooter";
import {MobiliteRooter} from "../pages/Mobilites/MobiliteRooter";
import {OffresRooter} from "../pages/Offres/OffresRooter";
import {ProfilRooter} from "../pages/Profil/ProfilRooter";
import {CompRooter} from "../pages/Competences/CompRooter";



export const Particulier = ()=>{
  const memberQuery = useGetMemberMe();
  const size = useWindowSize();
  useEffect(() => {
    console.log(size);
  }, [size]);
  const BlockMenus:MbjBlockMenu[] =[
    {libelle:"Home",nivo:[-1], to:"dashboard", toDirect:"dashboard", icon:<IoHome title="home icône" />},
    {libelle:"Cv",nivo:[-1], to:"cv", toDirect:"cv", icon:<GiSkills title="cv icône" />},
    {libelle:"Compétences",nivo:[-1], to:"competences", toDirect:"competences", icon:<BsFillBriefcaseFill title="comp icône" />},
    {libelle:"Mobilités",nivo:[-1], to:"mobilites", toDirect:"mobilites", icon:<TiFlowSwitch title="mobilités icône" />},
    {libelle:"Profil",nivo:[-1], to:"profil", toDirect:"profil", icon:<FaUser title="profil icône" />},
  ]
  return (
    <div className={`particulier`}>
      <Routes>
        <Route
          element={
            size && size.width && size.width > 600 ? (
              <DesktopLayout
                Blocks={BlockMenus}
                type={"Particulier"}
                DisconnectCallBack={()=>logout(queryClient)}
                numVersion={app_version}
                userLogo={
                  memberQuery.isLoading ? (
                    <MbjLoading height={"50%"} />
                  ) : memberQuery.isError ? (
                    <p>Error !</p>
                  ) : memberQuery.data?.logo !== null ? (
                    <img src={`${process.env["REACT_APP_API_URL_ROOT"]}/logos/members/${memberQuery.data.logo}`} />
                  ) : (
                    <FaUserCircle className={"icon-profile-menu"} />
                  )
                }
              />
            ) : (
              <MobileLayout
                Blocks={BlockMenus}
                DisconnectCallBack={() => logout(queryClient)}
              />
            )
          }
        >
          <Route path={"/"} element={<Navigate to={"dashboard"}/>}/>
          <Route path={"/dashboard/*"} element={<DashRooter/>}/>
          <Route path={"/cv/*"} element={<CvRooter/>}/>
          <Route path={"/mobilites/*"} element={<MobiliteRooter/>}/>
          <Route path={"/competences/*"} element={<CompRooter/>}/>
          <Route path={"/profil/*"} element={<ProfilRooter/>}/>
          <Route path={"/*"} element={<NotFound/>}/>
        </Route>
      </Routes>
    </div>

    )
}
