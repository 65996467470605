import styled from "styled-components";
import {MobiliteExternePart} from "./02_Externe/Particulier/MobiliteExternePart";
import {MobiliteExterneEmpl} from "./02_Externe/Employee/MobiliteExterneEmpl";

interface MobiliteExterneCtrlProps {
  className?:string;
}

const MobiliteExterneCtrl = ({className}: MobiliteExterneCtrlProps) => {
  const role = localStorage.getItem("__user_role__");
  return (
    <div className={`ext_mob ${className}`}>
      {role === 'particulier' ?
        <MobiliteExternePart/> : <MobiliteExterneEmpl/>
      }
    </div>
  )
}

export const MobiliteExterne = styled(MobiliteExterneCtrl)``
