import {Navigate, Route, Routes} from "react-router-dom";
import {NotFound} from "../../components/NotFound/NotFound";
import {LayoutMobilites} from "./components/LayoutMobilites";
import {MobiliteInterne} from "./MobiliteInterne";
import {MobiliteExterne} from "./MobiliteExterne";


export const MobiliteRooter = ()=>{
  return (
    <Routes>
      <Route element={<LayoutMobilites/>}>
        <Route path={"/"} element={<Navigate to={"externe"}/>}/>
        <Route path={"interne"} element={<MobiliteInterne/>}/>
        <Route path={"externe"} element={<MobiliteExterne/>}/>
        <Route path={"*"} element={<NotFound/>}/>
      </Route>
    </Routes>
  )
}
