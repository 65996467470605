import styled from "styled-components";
import {useGetEmployeeMe, useGetFI} from "@mbj-front-monorepo/data-access";
import {MbjButton, MbjLoading, MbjModalFullMobile, useModal} from "@mbj-front-monorepo/ui";
import {CvFiEmployeeCard} from "./CvFiEmployeeCard";
import {GoPlus} from "react-icons/go";
import * as React from "react";
import {MbjEmployeeFiNewForm, MbjEmployeeXpNewForm} from "@mbj-front-monorepo/data-ui";

interface CvEmployeeFiCtrlProps {
  className?:string;
}

const CvEmployeeFiCtrl = ({className}: CvEmployeeFiCtrlProps) => {
  const EmployeeQuery = useGetEmployeeMe();
  const EmployeeFIQuery = useGetFI(EmployeeQuery.data?.id||undefined)
  const {isShowing, toggle} = useModal()
  return (
    <div className={`part_fi ${className}`}>
      {EmployeeQuery.isLoading ||EmployeeFIQuery.isLoading ?
        <MbjLoading/>:
        <div className="contentFI">
          <div className="wrap_btn">
            <MbjButton leftIcon={<GoPlus/>} onClick={toggle} alignSelf={"flex-end"}>
              Ajouter une formation
            </MbjButton>
          </div>
          {EmployeeFIQuery.data?.map((item, idx) => (
            <CvFiEmployeeCard EmployeeFi={item} key={`oneFI${idx}`}/>
          ))}
        </div>
      }
      <MbjModalFullMobile open={isShowing} closeMe={toggle} titre={"Ajouter une expérience"}>
        <div className="wrap_form" style={{padding:"1rem"}}>
          <MbjEmployeeFiNewForm hideModal={toggle} idEmployee={EmployeeQuery.data?.id||0} isMobile={true}/>
        </div>
      </MbjModalFullMobile>
    </div>
  )
}

export const CvEmployeeFi = styled(CvEmployeeFiCtrl)`
  height: 100%;
  .wrap_btn{
    display: flex;
    justify-content: flex-end;
    padding: 0 0.2rem;
  }
  .contentFI {
    overflow-y: auto;
    padding: 20px 0;
  }
`
