import styled from "styled-components";
import {useGetFiPart, useGetParticulierMe} from "@mbj-front-monorepo/data-access";
import {MbjButton, MbjLoading, MbjModalFullMobile, useModal} from "@mbj-front-monorepo/ui";
import {GoPlus} from "react-icons/go";
import {CvFiEmployeeCard} from "../Employee/CvFiEmployeeCard";
import {MbjEmployeeFiNewForm, MbjParticulierFiNewForm} from "@mbj-front-monorepo/data-ui";
import * as React from "react";

interface CvParticulierFiCtrlProps {
  className?:string;
}

const CvParticulierFiCtrl = ({className}: CvParticulierFiCtrlProps) => {
  const particulierQuery = useGetParticulierMe();
  const FisQuery = useGetFiPart(particulierQuery.data ? particulierQuery.data.id||undefined : undefined);
  const {isShowing, toggle} = useModal()
  return (
    <div className={`part_fi ${className}`}>
      {particulierQuery.isLoading ||FisQuery.isLoading ?
        <MbjLoading/>:
        <div className="contentFI">
          <div className="wrap_btn">
            <MbjButton leftIcon={<GoPlus/>} onClick={toggle} alignSelf={"flex-end"}>
              Ajouter une formation
            </MbjButton>
          </div>
          {FisQuery.data?.map((item, idx) => (
            <CvFiEmployeeCard EmployeeFi={item} key={`oneFI${idx}`}/>
          ))}
        </div>
      }
      <MbjModalFullMobile open={isShowing} closeMe={toggle} titre={"Ajouter une formation"}>
        <div className="wrap_form" style={{padding:"1rem"}}>
          <MbjParticulierFiNewForm hideModal={toggle} idParticulier={particulierQuery.data?.id||0} isMobile={true}/>
        </div>
      </MbjModalFullMobile>
    </div>
  )
}

export const CvParticulierFi = styled(CvParticulierFiCtrl)`
  height: 100%;
  .wrap_btn{
    display: flex;
    justify-content: flex-end;
    padding: 0 0.2rem;
  }
  .contentFI {
    overflow-y: auto;
    padding: 20px 0;
  }
`
