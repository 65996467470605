import styled from "styled-components";
import {HeaderMobileSwitchMenu, MbjMobileMenuSwitch} from "@mbj-front-monorepo/ui";
import {Outlet, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

interface LayoutMobilitesCtrlProps {
  className?:string;
}

const Menus:MbjMobileMenuSwitch[] = [
  {libelle:"Interne", to:'interne'},
  {libelle:"Externe", to:'externe'},
]
const LayoutMobilitesCtrl = (props: LayoutMobilitesCtrlProps) => {
  const location = useLocation()
  const [Current, setCurrent] = useState('')
  const [myMenus, setMyMenus] = useState<MbjMobileMenuSwitch[]>(Menus);
  const role = localStorage.getItem("__user_role__");
  useEffect(() => {
    if(role === 'particulier'){
      setMyMenus([
        {libelle:"Externe", to:'externe'},
      ])
    }
  }, [role]);
  useEffect(() => {
    const pathname = location.pathname;
    const TabP = pathname.split('/')
    console.log(TabP[3]);
    setCurrent(TabP[3] === 'externe' ? 'Externe' : 'Interne')
  }, [location]);
  return (
    <div className={`LayoutsMobilites ${props.className}`}>
      <HeaderMobileSwitchMenu Menus={myMenus} Current={Current}/>
      <div className="contentMob">
        <Outlet/>
      </div>
    </div>
  )
}

export const LayoutMobilites = styled(LayoutMobilitesCtrl)`
  display: flex;
  flex-direction: column;
  height: 100%;
  .contentMob{
    flex-grow: 1;
    overflow-y: auto;
  }
`
