import styled from "styled-components";
import {MbjParticulierInformationsCard} from "@mbj-front-monorepo/data-ui";
import {useGetParticulierMe} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";

interface ProfilParticulierCtrlProps {
  className?:string;
}

const ProfilParticulierCtrl = ({className}: ProfilParticulierCtrlProps) => {
  const particulierQuery = useGetParticulierMe();
  return (
    <div className={`profil_part ${className}`}>
      {particulierQuery.isLoading ?
      <MbjLoading/>:
        !particulierQuery.data ?
          <p>Error</p>:
          <div style={{background:"white", display:"flex", flexDirection:"column", justifyContent:"center"}}>
            <MbjParticulierInformationsCard particulierId={particulierQuery.data?.id||0} isMobile={true}/>
          </div>
      }

    </div>
  )
}

export const ProfilParticulier = styled(ProfilParticulierCtrl)`
  height: 100%;
`
