import styled from "styled-components";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {Operation} from "@mbj-front-monorepo/data-access";
import React from "react";
import {IoLocationSharp, IoSchoolSharp} from "react-icons/io5";
import {GiPathDistance} from "react-icons/gi";
import {CgWebsite} from "react-icons/cg";

interface LineFormationCourteCtrlProps {
  className?:string;
  FormationCourte: GetElementType<GetElementType<Operation<"getFormationMetierCollection">>["FormationsCourtesMetier"]>;
}

const LineFormationCourteCtrl = ({className, FormationCourte}: LineFormationCourteCtrlProps) => {
  return (
    <div className={`line_formation_courte ${className}`}>
      <div className={`name_formation`}>{FormationCourte.libelle || ''}</div>
      {FormationCourte.OrganismeFormationDistants && FormationCourte.OrganismeFormationDistants.length === 0 &&
        <p>Aucune formation courtes trouvées</p>
      }
      <ul className="list-unstyled">
        {FormationCourte.OrganismeFormationDistants?.map((item, idx: number) => (
          <li key={item.id}>
            <div className="one-OF">
              <div className={"name-of"}>
                <IoSchoolSharp/>
                <span className={"ms-2"}>{item.rs}</span>
              </div>
              <div className={"infos-of"}>
                <div className="one-info">
                  <GiPathDistance/>
                  <span>{item.distance ? Math.round(item.distance) : ""}km</span>
                </div>
                {item.siteWeb !== null && (
                  <div className="one-info">
                    <CgWebsite/>
                    <a href={item.siteWeb}>{item.siteWeb}</a>
                  </div>
                )}
                <div className="one-info">
                  <IoLocationSharp/>
                  <span>{item.adresse ? item.adresse : item.ville}</span>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const LineFormationCourte = styled(LineFormationCourteCtrl)`
  margin: 15px auto;
  .name_formation{
    font-weight: bold;
  }
  .one-info, .name-of{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
  }
  ul{
    li{
      list-style: none;
      padding-left: 15px;
    }
  }
`
