import styled from "styled-components";
import {getDateFR, GetElementType} from "@mbj-front-monorepo/utils";
import {Operation} from "@mbj-front-monorepo/data-access";
import {MbjStaticInfo} from "@mbj-front-monorepo/ui";
import React from "react";

interface CvXpParticulierCardCtrlProps {
  className?:string;
  ParticulierXp: GetElementType<Operation<"getParticulierXpCollection">>;
}

const CvXpParticulierCardCtrl = ({className, ParticulierXp}: CvXpParticulierCardCtrlProps) => {
  return (
    <div className={`CvEmployeeCvCard ${className}`}>
      <div className="HeadCard">
        <div className="dates">
          Du {ParticulierXp.startAt ? getDateFR(new Date(ParticulierXp.startAt)) : "inconnu"} au{" "}
          {ParticulierXp.endAt ? getDateFR(new Date(ParticulierXp.endAt)) : "maintenant"}
        </div>
        <div className={`name_xp`}>{ParticulierXp.Metier?.libelle}</div>
      </div>
      <div className="BodyCard">
        <MbjStaticInfo
          label="Type de contrat : "
          info={ParticulierXp.TypeContrat ? ParticulierXp.TypeContrat.libelle : "inconnu"}
          direction={"row"}
          gap={"5px"}
        />
        <MbjStaticInfo
          label="Salaire : "
          info={ParticulierXp.salary ? ParticulierXp.salary + "€" : "inconnu"}
          direction={"row"}
          gap={"5px"}
        />
        {ParticulierXp.description && (
          <MbjStaticInfo label="Description : " info={ParticulierXp.description} gap={"5px"}/>
        )}
      </div>
    </div>
  )
}

export const CvXpParticulierCard = styled(CvXpParticulierCardCtrl)`
  background: white;
  margin: 10px 0;
  .HeadCard{
    padding: 0.5rem 0.5rem;
    .dates{
      font-weight: bold;
    }
    .name_xp{
      color: ${props=>props.theme.primary};
      font-weight: bold;
    }
  }
  .BodyCard{
    padding: 0.25rem 0.75rem;
  }
`
