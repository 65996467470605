import styled from "styled-components";
import {useGetEmployeeMe, useGetMobilites} from "@mbj-front-monorepo/data-access";
import {useMemo, useRef, useState} from "react";
import {MbjListItem, MbjLoading, MbjScrollArea, MbjSearchBarMobile} from "@mbj-front-monorepo/ui";
import {MbjMobiliteListItem} from "@mbj-front-monorepo/data-ui";
import * as React from "react";

interface AllMobExterneEmplCtrlProps {
  className?:string;
  setIdMetier:(id:number)=>void;
}

const AllMobExterneEmplCtrl = ({className, setIdMetier}: AllMobExterneEmplCtrlProps) => {
  const EmployeeQuery = useGetEmployeeMe();
  const mobilitesQuery = useGetMobilites(EmployeeQuery.data?.id||-1);
  const [search, setSearch] = useState<string>("");
  const refSearch = useRef<HTMLInputElement>(null);
  const ListMobiliteFiltered = useMemo(()=>{
    if (mobilitesQuery.isLoading || mobilitesQuery.isError || !mobilitesQuery.data) {
      return [];
    }
    return mobilitesQuery.data
      ?.filter((mob) => {
        if (search === "") {
          return mob;
        } else if (mob.Metier?.libelle?.toLowerCase().includes(search.toLowerCase())) {
          return mob;
        }
        return undefined;
      })
      .sort((a, b) => {
        return (a.Metier ? (a.Metier.libelle|| "0") : "0") > (b.Metier ? (b.Metier.libelle|| "0") : "0") ? 1 : -1;
      });
  }, [search, mobilitesQuery.data, mobilitesQuery.isLoading, mobilitesQuery.isError])
  const handleChange = ()=>{
    if(refSearch.current){
      setSearch(refSearch.current.value)
    }
  }
  const setMet = (item:{id:number, libelle:string})=>{
    setIdMetier(item.id)
  }
  return (
    <div className={`all_mob_externe_emp ${className}`}>
      <div className="search">
        <input type={"text"} ref={refSearch} value={search} onChange={handleChange} placeholder={"Filtrer les métiers"}/>
      </div>
      <div className={`wrap_result`}>
        {mobilitesQuery.isLoading ? (
          <MbjLoading />
        ) : mobilitesQuery.isError ? (
          "Errors"
        ) : (
          ListMobiliteFiltered
            .map((mobilite, idx: number) => (
              <MbjListItem key={mobilite.id} onClick={()=>setIdMetier(mobilite.Metier?.id||0)}>
                <MbjMobiliteListItem mobilite={mobilite} className="in-line" />
              </MbjListItem>
            ))
        )}
      </div>
    </div>
  )
}

export const AllMobExterneEmpl = styled(AllMobExterneEmplCtrl)`

  .search{
    margin-bottom: 10px;
    width: 100%;
    padding: 0 0.25rem;
  }
  input{
    width: 100%;
    height: 32px;
    padding-left: 5px;
    border-radius: 4px;
    border:solid 1px ${props => props.theme.primary};
  }
  .wrap_result{
    height: 150px;
    overflow-y: auto;
    background: white;
    padding: 0.25rem;
  }
`
