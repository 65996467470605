import styled from "styled-components";
import {MobiliteInterneEntretien} from "./01_Interne/MobiliteInterneEntretien";
import {MbjModalFullMobile, useModal} from "@mbj-front-monorepo/ui";
import {useState} from "react";
import {AllMobInterne} from "./01_Interne/AllMobInterne";
import {useGetEmployeeMe} from "@mbj-front-monorepo/data-access";
import {CompareToUserMetier} from "./01_Interne/components/CompareToUserMetier";

interface MobiliteInterneCtrlProps {
  className?:string;
}
interface ForProxi{
  idCompareA:number;
  idCompareB:number;
}
const MobiliteInterneCtrl = ({className}: MobiliteInterneCtrlProps) => {
  const { isShowing, toggle } = useModal();
  const [forP, setForP] = useState<ForProxi|null>(null);
  const EmployeeQuery = useGetEmployeeMe();
  const setForPTrigger = (idA:number, idB:number)=>{
    setForP({idCompareA:idA, idCompareB:idB})
    toggle();
  }
  return (
    <div className={`mobilite_interne ${className}`}>
      <div className="block_mob">
        <div className="titre_block">Les mobilités exprimées lors du dernier entretien</div>
        <div className="content_mob">
          <MobiliteInterneEntretien setForPTrigger={setForPTrigger}/>
        </div>
      </div>
      <div className="block_mob">
        <div className="titre_block">Toutes les mobilités internes</div>
        <div className="content_mob">
          <AllMobInterne setForPTrigger={setForPTrigger}/>
        </div>
      </div>
      <MbjModalFullMobile open={isShowing} closeMe={toggle} titre={"Comparaison"}>
        <CompareToUserMetier idEmployee={EmployeeQuery.data?.id||0}  idUserMetier={forP?.idCompareB||0}/>
      </MbjModalFullMobile>
    </div>
  )
}

export const MobiliteInterne = styled(MobiliteInterneCtrl)`
  padding: 2rem 0.25rem 0.5rem;
  .block_mob{
    background: white;
    padding: 0.5rem;
    margin-bottom: 20px;
    .titre_block{
      font-size: 16px;
      color: ${props => props.theme.primary};
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .content_mob{
    max-height: 300px;
    overflow-y: auto;
  }
`
