import styled from "styled-components";
import {useGetEmployeeMe, useGetMobilitesInternes} from "@mbj-front-monorepo/data-access";
import {MbjErrorData, MbjListItem, MbjLoading} from "@mbj-front-monorepo/ui";
import {MbjMobiliteListItem} from "@mbj-front-monorepo/data-ui";
import * as React from "react";

interface AllMobInterneCtrlProps {
  className?:string;
  setForPTrigger:(idA:number, idB:number)=>void;
}

const AllMobInterneCtrl = ({className, setForPTrigger}: AllMobInterneCtrlProps) => {
  const EmployeeQuery = useGetEmployeeMe();
  const MobilitesQuery = useGetMobilitesInternes(EmployeeQuery.data?.id||undefined);
  return (
    <div className={`all_mob_inter ${className}`}>
      {MobilitesQuery.isLoading ? <MbjLoading/> : MobilitesQuery.isError ? <MbjErrorData/> :
        MobilitesQuery.data?.map((item, idx)=>(
          <MbjListItem key={`one_mobilite_interne${idx}`} onClick={()=>setForPTrigger((item.id||0), (item.userMetier?.id||0))}>
            <MbjMobiliteListItem libelle={item.userMetier?.namePerso} score={item.score} />
          </MbjListItem>
        ))
      }
    </div>
  )
}

export const AllMobInterne = styled(AllMobInterneCtrl)``
