import styled from "styled-components";
import {CvParticulier} from "../CV/Particulier/CvParticulier";
import {CvEmployee} from "../CV/Employee/CvEmployee";
import {CompParticulier} from "./Particulier/CompParticulier";
import {CompEmployee} from "./Employee/CompEmployee";

interface CompMainCtrlProps {
  className?:string;
}

const CompMainCtrl = ({className}: CompMainCtrlProps) => {
  const role = localStorage.getItem("__user_role__");
  return (
    <div className={`comp ${className}`}>
      {role === 'particulier' ?
        <CompParticulier/> : <CompEmployee/>
      }
    </div>
  )
}

export const CompMain = styled(CompMainCtrl)`
  height: 674px;
  overflow-y: auto;
`
