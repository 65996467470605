import styled, {useTheme} from "styled-components";
import {useWindowSize} from "@mbj-front-monorepo/utils";
import {LoginForm} from "./LoginForm";
import {useLocation} from "react-router-dom";
import React from "react";

interface LoginCtrlProps {
  className?:string;
}

const LoginCtrl = (props: LoginCtrlProps) => {
  const size = useWindowSize();
  const location = useLocation();
  const theme = useTheme();
  return (
    <div className={`particulier_login ${props.className}`}>
      {(size && (size.width||0) > 600) ?
        <div className={`backImg`}>
          <img src={theme.logo} alt="logo mbj"/>
        </div>:
        <div className={`back_logo`}>
          <img src={theme.logo} alt="logo mbj"/>
        </div>
      }
      <div className={`login_form ${size && size.width && size.width > 600 ? '--desktop' : '--mobile'}`}>
          <h1>Connexion</h1>
          <LoginForm location={location}/>
        </div>
    </div>
  )
}

export const Login = styled(LoginCtrl)`
  position:relative;
  height: 100vh;
  h1{
    text-align: center;
    margin-bottom: 20px;
  }
  .back_logo{
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: ${props =>props.theme.dark};
    img{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 30px;
      max-width: 240px;
      width: 60%;
      zIndex: 1;
    }
  }
  .backImg{
    position: fixed;
    left: 0;
    height: 100%;
    width: 300px;
    background: ${props =>props.theme.dark};
    box-shadow: 6px 0px 11px #00000040;
    img{
        position: absolute;
        left: 10px;
        top: 10px;
        max-width: 240px;
        width: 80%;
        zIndex: 1;
    }
  }
  .login_form{
    background: white;
    border-top:solid 30px ${props=>props.theme.primaryPastel};
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 30%;
    padding: 1rem;
    position: absolute;
    left: 150px;
    top:50%;
    transform: translateY(-50%);
    &.--mobile{
      left: 50%;
      transform: translate(-50%,-50%);
      width: 90%;
    }
  }
`
