import styled from "styled-components";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {Operation} from "@mbj-front-monorepo/data-access";
import {IoLocationSharp, IoSchoolSharp} from "react-icons/io5";
import {GiPathDistance} from "react-icons/gi";
import {CgWebsite} from "react-icons/cg";
import React from "react";

interface LineFormationMetierCtrlProps {
  className?:string;
  FormationContinuMetier: GetElementType<
    GetElementType<Operation<"getFormationMetierCollection">>["FormationContinusMetier"]
  >;
}

const LineFormationMetierCtrl = ({className, FormationContinuMetier}: LineFormationMetierCtrlProps) => {
  return (
    <div className={`line_formation_metier ${className}`}>
      <div className={`name_formation`}>{FormationContinuMetier.libelle||''}</div>
      <div className="wrap_forms">
        <ul className="list-unstyled">
          {FormationContinuMetier.OrganismeFormationDistants?.map((item, idx: number) => (
            <li key={item.id}>
              <div className="one-OF">
                <div className={"name-of"}>
                  <IoSchoolSharp/>
                  <span className={"ms-2"}>{item.rs}</span>
                </div>
                <div className={"infos-of"}>
                  <div className="one-info">
                    <GiPathDistance/>
                    <span>{item.distance ? Math.round(item.distance) : ""}km</span>
                  </div>
                  {item.siteWeb !== null && (
                    <div className="one-info">
                      <CgWebsite/>
                      <a target={"_blank"} href={item.siteWeb}>{item.siteWeb}</a>
                    </div>
                  )}
                  <div className="one-info">
                    <IoLocationSharp/>
                    <span>{item.adresse ? item.adresse : item.codePostal + " " + item.ville}</span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export const LineFormationMetier = styled(LineFormationMetierCtrl)`
  margin: 15px auto;
  .name_formation{
    font-weight: bold;
  }
  .one-info, .name-of{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
  }
  ul{
    li{
      list-style: none;
      padding-left: 15px;
    }
  }
`
