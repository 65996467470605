import styled, {keyframes} from "styled-components";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {BsTrash} from "react-icons/bs";
import {MbjIconButton, MbjModalFullMobile, useModal} from "@mbj-front-monorepo/ui";
import {MyActivite} from "../CompetencesInterfaces";
import {CompetenceRooterContext} from "../CompRooter";
import {ModalPropositionNiveau} from "./ModalPropositionNiveau";


interface OneActiviteRCtrlProps{
  className?:string;
  Activite:MyActivite;
}

const OneActiviteRCtrl = (props:OneActiviteRCtrlProps)=>{
  const {id} = useParams();
  const context = useContext(CompetenceRooterContext);
  const refWrappy = useRef<HTMLDivElement>(null);
  const refWrappy2 = useRef<HTMLDivElement>(null);
  const [widthOver, setWidthOver] = useState(0);
  const [wrappyStdvis, setWrappyStdvis] = useState(true);
  const {isShowing, toggle} = useModal();
  const ClickOn = (e:any)=>{
    if(id && context && context.ClickNiveau && !context.ReadOnly){
      console.log('click>>>'+widthOver/20);
      context.ClickNiveau(props.Activite.id, widthOver/20, props.Activite.idObject||0)
    }
  }
  const TrashThisLine = ()=>{
    if(context && context.Trash){
      context.Trash(props.Activite.id)
    } else {
      alert("Pas de fonction de suppression")
    }
  }
  const ClickOnLibelle = ()=>{
    if(context.idEmp!==0){
      toggle();
    }
  }
  useEffect(() => {
    const handle = (e:any)=>{
      if(refWrappy.current && !context.ReadOnly){
        const elt = refWrappy.current.getBoundingClientRect();
        const leftE = elt.left;
        const topE = elt.top;
        const widthE = elt.width;
        const heightE = elt.height;
        const leftMe = e.clientX;
        const topMe = e.clientY;
        if((leftMe>leftE && leftMe < (leftMe+widthE)) && (topMe>topE && topMe<(topE+heightE))){
          setWrappyStdvis(false);
          console.log('on_it')
          const lg = (leftMe - leftE)/widthE*100;
          let wTo=0;
          if(lg<20){
            wTo = 20
          } else if(lg<40){wTo = 40}
          else if(lg<60){wTo = 60}
          else if(lg<80){wTo = 80}
          else {wTo = 100}
          setWidthOver(wTo);
        } else {
          setWrappyStdvis(true);
          setWidthOver(0);
        }
      }
    }
    document.addEventListener('mousemove', handle);
    return ()=>{
      document.removeEventListener('mousemove', handle);
    }
  }, [refWrappy, context]);
  return (
    <div className={`one_activite ${props.className}`}>
      <div className={`content_link`}>
        <div className={`libelle`}>
          <div className={`content_libelle`} onClick={ClickOnLibelle}>
            {!context.ReadOnly &&
              <MbjIconButton
                  onClick={TrashThisLine}
                  ariaLabel={"Supprimer cette compétence"}
                  icon={<BsTrash />}
                  themeColor={"warning"}
                  size={"xs"}
                  isRound={false}
                  disabled={props.Activite.Niveau === 0}
              />
            }
            <span>{props.Activite.libelle}</span>

          </div>
        </div>
        <div className={`wrapper_wrapper`}>
          <div className={`grid`}>
            <div className="grid_nivo"/>
            <div className="grid_nivo"/>
            <div className="grid_nivo"/>
            <div className="grid_nivo"/>
            <div className="grid_nivo"/>
          </div>
          <div className={`wrapper_niveau`} ref={refWrappy} onClick={ClickOn}>
            <div className={`wrappy_niveau ${wrappyStdvis ? 'vis' : 'invisible'}`} style={{width:(props.Activite.Niveau/5*100)+"%"}}/>
            <div className={`wrappy_niveau`} style={{width:widthOver+"%"}} ref={refWrappy2}/>
          </div>
        </div>
      </div>
      <MbjModalFullMobile open={isShowing} closeMe={toggle} titre={"Proposition niveau"}>
        <ModalPropositionNiveau Activite={props.Activite} idEmp={context.idEmp||0} closeMe={toggle}/>
      </MbjModalFullMobile>
    </div>
  )
}

const Progress = () => keyframes`
    from {
        width: 0;
    }

`;

export const OneActiviteR = styled(OneActiviteRCtrl)`
  color:black;
  display: block;
  .content_link{
    border-top: solid ${props=>props.theme.greyLighten} 1px;
    display: flex;
    justify-content: stretch;
  }
  .libelle{
    font-size: 14px;
    font-weight: bold;
    color:${props=>props.theme.dark};
    width: 40%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content_libelle{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
    }
  }
  .wrapper_wrapper{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  .grid{
    display: flex;
    z-index: 2;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    .grid_nivo{
      border-right: dashed ${props=>props.theme.grey} 1px;
      width: 20%;
    }
  }
  .wrapper_niveau{
    width: 100%;
    height: 20px;
    border-radius: 4px;
    background: #ccc;
    overflow: hidden;
    z-index: 3;
    position: absolute;
    left: 0;
    top:50%;
    transform: translateY(-50%);
    &:hover{
      cursor: pointer;
    }
  }
  .wrappy_niveau{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: ${props=>props.theme.third};
    transition: width 0.3s;
    animation: ${Progress} 1.5s ease-out;
    border-radius: 4px;
    &.invisible{
      opacity: 0;
    }
  }
`
