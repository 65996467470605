import styled from "styled-components";
import {useContext} from "react";
import {DomaineContext} from "./OneDomaineR";

import * as React from "react";
import {LinkCompR} from "./LinkCompR";
import {useWindowSize} from "@mbj-front-monorepo/utils";

interface ListFamilleRCtrlProps{
  className?:string;
}

const ListFamilleRCtrl = (props:ListFamilleRCtrlProps)=>{
  const size = useWindowSize()
  const context = useContext(DomaineContext);
  return (
    <div className={`list_domaines ${props.className}  ${size.width && size.width > 600 ? '' : '--mobile'}`}>
      <h1>Profil de compétence par Famille dans le domaine {context.Domaine ? context.Domaine.libelle : ''}</h1>
      <div className={`wrap_list`}>
        {context.Familles.sort((a,b)=>(a.NiveauTotal||0)/(context.Domaine?.NiveauTotal||1) > (b.NiveauTotal||0)/(context.Domaine?.NiveauTotal||1) ? -1 : 1).map(item=>(
          <LinkCompR to={item.id + ""} libelle={item.libelle} key={`one_linkF${item.id}`} niveau={(item.NiveauTotal||0)/(context.Domaine?.NiveauTotal||1) } isN={true}/>
        ))}
      </div>
    </div>
  )
}

export const ListFamilleR = styled(ListFamilleRCtrl)`
  padding: 1% 2%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &.--mobile{
    padding: 0;
    h1{
      font-size: 16px;
      padding: 10px;
      margin-bottom: 20px;
    }
    .wrap_list{
      padding: 0 10px;
    }
  }
  h1{
    font-size: 18px;
    margin-bottom: 20px;
  }
  .wrap_list{
    flex-grow: 1;
    overflow: auto;
  }
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
`
