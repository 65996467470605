import styled from "styled-components";
import {EmployeeProfilRooter} from "./Employee/EmployeeProfilRooter";
import {ProfilParticulier} from "./Particulier/ProfilParticulier";

interface ProfilMainCtrlProps {
  className?:string;
}

const ProfilMainCtrl = (props: ProfilMainCtrlProps) => {
  const role = localStorage.getItem("__user_role__");
  return (
    <div className={`profil ${props.className}`}>
      {role !== 'particulier' ?
        <EmployeeProfilRooter/> : <ProfilParticulier/>
      }
    </div>
  )
}

export const ProfilMain = styled(ProfilMainCtrl)`
  height: 100%;
  overflow-y: auto;
`
