import styled from "styled-components";
import {CvParticulier} from "./Particulier/CvParticulier";
import {CvEmployee} from "./Employee/CvEmployee";

interface CvMainCtrlProps {
  className?:string;
}

const CvMainCtrl = (props: CvMainCtrlProps) => {
  const role = localStorage.getItem("__user_role__");
  return (
    <div className={`cv ${props.className}`}>
      {role === 'particulier' ?
        <CvParticulier/> : <CvEmployee/>
      }
    </div>
  )
}

export const CvMain = styled(CvMainCtrl)`
  height: 100%;
`
