import styled from "styled-components";

interface CompetenceCompareCardCtrlProps {
  className?:string;
  libelle:string;
  nivo:number;
  nivoA:number;
}

const CompetenceCompareCardCtrl = ({className, nivo, nivoA, libelle}: CompetenceCompareCardCtrlProps) => {
  return (
    <div className={`comp_card_cop ${className}`}>
      <div className="lib_card">{libelle}</div>
      <div className="oneSlider is-att">
        <div className="slider">
          <div className="slidy_in  is-att" style={{width: (nivoA / 5) * 100}}/>
        </div>
      </div>
      <div className="oneSlider is-actual">
        <div className="slider">
          <div className="slidy_in is-actual" style={{width: (nivo / 5) * 100}}/>
        </div>
      </div>
    </div>
  )
}

export const CompetenceCompareCard = styled(CompetenceCompareCardCtrl)`
  .oneSlider {
    margin-bottom: 4px;
  }
    .lib_card{
      color: ${props => props.theme.primary};
    }
  .slider {
    width: 100%;
    border-radius: 4px;
    height: 5px;
    position: relative;
    .slidy_in{
      position: absolute;
      left: 0;
      height: 100%;
      background: #ccc;
      &.is-att{
        background: ${props => props.theme.secondaryLighten};
      }
      &.is-actual{
        background: ${props => props.theme.thirdLighten};
      }
    }
  }
`
