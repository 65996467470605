import styled from "styled-components";
import {HeaderMobileSwitchMenu, MbjMobileMenuSwitch} from "@mbj-front-monorepo/ui";
import {Outlet, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

interface LayoutProfilCtrlProps {
  className?:string;
}

const Menus:MbjMobileMenuSwitch[] = [
  {libelle:"A améliorer", to:'to_rise'},
  {libelle:"Formations", to:'formations'},
]
const LayoutProfilCtrl = (props: LayoutProfilCtrlProps) => {
  const location = useLocation()
  const [Current, setCurrent] = useState('')
  useEffect(() => {
    const pathname = location.pathname;
    const TabP = pathname.split('/')
    console.log(TabP[3]);
    setCurrent(TabP[4] === 'to_rise' ? 'A améliorer' : 'Formations')
  }, [location]);
  return (
    <div className={`LayoutsProfil ${props.className}`}>
      <HeaderMobileSwitchMenu Menus={Menus} Current={Current}/>
      <div className="contentMob">
        <Outlet/>
      </div>
    </div>
  )
}

export const LayoutProfil = styled(LayoutProfilCtrl)`
  display: flex;
  flex-direction: column;
  height: 100%;
  .contentMob{
    flex-grow: 1;
    overflow-y: auto;
  }
`
