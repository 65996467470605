import {Navigate, Route, Routes} from "react-router-dom";
import {NotFound} from "../../components/NotFound/NotFound";
import {Dashboard} from "./Dashboard";


export const DashRooter = ()=>{
  return (
    <Routes>
      <Route path={"/"} element={<Navigate to={"main"}/>}/>
      <Route path={"main"} element={<Dashboard/>}/>
      <Route path={"*"} element={<NotFound/>}/>
    </Routes>
  )
}
