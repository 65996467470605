import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchApi, Operation } from "../../services/fetchApi";

export const useGetEntretienAnnuels = (employeeId?: number) => {
  const promise = () => fetchApi("/api/entretien_annuels", { method: "get", query: { Employee: employeeId + "" } });
  return useQuery(["entretien_annuels", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useGetEntretienAnnuelSlugE = (slug:string)=>{
  const promise = () => fetchApi("/api/entretien_annuels", { method: "get", query: { slugSignature: slug + "" } });
  return useQuery(["entretien_annuel_slug_e", { slug }], promise, {
    enabled: slug!=='',
  });
}

export const useGetEntretienAnnuelCodeE = (code:string)=>{
  const promise = () => fetchApi("/api/entretien_annuels", { method: "get", query: { codeVerif: code + "" } });
  return useQuery(["entretien_annuel_code_e", { code }], promise, {
    enabled: code!=='',
  });
}

export const useGetEntretienAnnuelCodeR = (code:string)=>{
  const promise = () => fetchApi("/api/entretien_annuels", { method: "get", query: { codeVerifResponsable: code + "" } });
  return useQuery(["entretien_annuel_code_r", { code }], promise, {
    enabled: code!=='',
  });
}

export const useGetEntretienAnnuelSlugR = (slug:string)=>{
  const promise = () => fetchApi("/api/entretien_annuels", { method: "get", query: { slugSignatureResponsable: slug + "" } });
  return useQuery(["entretien_annuel_slug_r", { slug }], promise, {
    enabled: slug!=='',
  });
}



export const useAddNewEntretien = (employeeId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postEntretienAnnuelCollection", "requestBody">) => {
      return fetchApi("/api/entretien_annuels", {
        method: "post",
        json: { ...data, Employee: "/api/employees/" + employeeId },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["entretien_annuels", { employeeId }]);
        toast.success("Entretien créé !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useSendCodeEmployee = (entretienID: number) => {
  return useMutation(
    (data: { id:number }) => {
      return fetchApi(`/api/entretien_annuels/{id}/send_code_employee`, {
        method: "get",
        params: {id:entretienID+""},
      });
    }
  );
};

export const useSendCodeResponsable = (entretienID: number) => {
  return useMutation(
    (data: { id:number }) => {
      return fetchApi(`/api/entretien_annuels/{id}/send_code_responsable`, {
        method: "get",
        params: {id:entretienID+""},
      });
    }
  );
};

export const useSendSignature = (entretienID: number) => {
  return useMutation(
    (data: { id:number }) => {
      return fetchApi(`/api/entretien_annuels/{id}/signature`, {
        method: "get",
        params: {id:entretienID+""},
      });
    }
  );
};

export const useSendSignatureResponsable = (entretienID: number) => {
  return useMutation(
    (data: { id:number }) => {
      return fetchApi(`/api/entretien_annuels/{id}/signature_responsable`, {
        method: "get",
        params: {id:entretienID+""},
      });
    }
  );
};
