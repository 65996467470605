import styled from "styled-components";
import {useState} from "react";
import {MbjModalFullMobile, useModal} from "@mbj-front-monorepo/ui";
import {AllMobExterneEmpl} from "./AllMobExterneEmpl";
import {RecommandationMbj} from "./RecommandationMbj";
import {CompareToMetier} from "../components/CompareToMetier";
import {useGetEmployeeMe} from "@mbj-front-monorepo/data-access";

interface MobiliteExterneEmplCtrlProps {
  className?:string;
}

const MobiliteExterneEmplCtrl = ({className}: MobiliteExterneEmplCtrlProps) => {
  const EmployeeQuery = useGetEmployeeMe();
  const {isShowing, toggle} = useModal()
  const [idMetierCurrent, setidMetierCurrent] = useState<number>(0)
  const SetMetCurr = (id:number)=>{
    setidMetierCurrent(id)
    toggle();
  }
  return (
    <div className={`mob_ext_emp ${className}`}>
      <div className="block_mob">
        <div className="titre_block">Toutes les mobilités</div>
        <AllMobExterneEmpl setIdMetier={SetMetCurr}/>
      </div>
      <div className="block_mob">
        <div className="titre_block">Recommandé par mbj</div>
        <RecommandationMbj setIdMetier={SetMetCurr}/>
      </div>
      <MbjModalFullMobile open={isShowing} closeMe={toggle} titre={"Comparaison"}>
        <CompareToMetier idEmployee={EmployeeQuery.data?.id||0} idMetier={idMetierCurrent}/>
      </MbjModalFullMobile>
    </div>
  )
}

export const MobiliteExterneEmpl = styled(MobiliteExterneEmplCtrl)`
  padding: 1rem 0.25rem 0.5rem;
  height: 625px;
  overflow-y: auto;
  .block_mob{
    background: white;
    padding: 0.5rem;
    margin-bottom: 10px;
    .titre_block{
      font-size: 16px;
      color: ${props => props.theme.primary};
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
`
