import styled from "styled-components";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {Operation} from "@mbj-front-monorepo/data-access";
import React from "react";
import {MbjFlex, MbjStaticInfo} from "@mbj-front-monorepo/ui";

interface CvFiEmployeeCardCtrlProps {
  className?:string;
  EmployeeFi: GetElementType<Operation<"getEmployeeFICollection">>;
}

const CvFiEmployeeCardCtrl = (props: CvFiEmployeeCardCtrlProps) => {
  return (
    <div className={`FiCardEmp ${props.className}`}>
      <div className="HeadCard">
        {props.EmployeeFi.obtentionAt &&
          <span style={{display:"inline-block", width:"170px", flexShrink:0}}>Obtenu le {new Date(props.EmployeeFi.obtentionAt.slice(0,10)+" 12:00:00").toLocaleDateString()} :{" "}</span>
        }
        {props.EmployeeFi.nom}
      </div>
      <div className="BodyCard">
        <div className="oneInfo">
          <div className="label">Niveau d'étude</div>
          <div className="info">{props.EmployeeFi.NiveauEtude ? props.EmployeeFi.NiveauEtude.libelle : "inconnu"}</div>
        </div>
        <div className="oneInfo">
          <div className="label">Discipline</div>
          <div className="info">{props.EmployeeFi.Discipline ? props.EmployeeFi.Discipline.libelle : "inconnu"}</div>
        </div>
        {props.EmployeeFi.description && (
          <div className="oneInfo">
            <div className="label">Description</div>
            <div className="info">{props.EmployeeFi.description}</div>
          </div>
      )}
    </div>
</div>
)
}

export const CvFiEmployeeCard = styled(CvFiEmployeeCardCtrl)`
  background: white;
  margin: 10px 0;

  .HeadCard {
    padding: 0.5rem 0.5rem;
    font-weight: bold;

    .dates {
      font-weight: bold;
    }
    .name_xp{
      color: ${props=>props.theme.primary};
      font-weight: bold;
    }
  }
  .BodyCard{
    padding: 0.25rem 0.75rem;
  }
  .oneInfo{
    margin-bottom: 10px;
    .label{
      font-weight: bold;
      color: ${props=>props.theme.primary};
    }
  }
`
