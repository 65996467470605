import styled from "styled-components";
import {CvParticulier} from "./Particulier/CvParticulier";
import {CvEmployee} from "./Employee/CvEmployee";
import {CvParticulierFi} from "./Particulier/CvParticulierFi";
import {CvEmployeeFi} from "./Employee/CvEmployeeFi";

interface CvMainFiCtrlProps {
  className?:string;
}

const CvMainFiCtrl = (props: CvMainFiCtrlProps) => {
  const role = localStorage.getItem("__user_role__");
  return (
    <div className={`dash_part ${props.className}`}>
      {role === 'particulier' ?
        <CvParticulierFi/> : <CvEmployeeFi/>
      }
    </div>
  )
}

export const CvMainFi = styled(CvMainFiCtrl)``
