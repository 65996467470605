import {Navigate, Route, Routes} from "react-router-dom";
import {NotFound} from "../../../components/NotFound/NotFound";
import {LayoutProfil} from "../components/LayoutProfil";
import {ToRiseProfil} from "./ToRiseProfil";
import {FormationsProfil} from "./FormationsProfil";

export const EmployeeProfilRooter = ()=>{
  return (
    <Routes>
      <Route element={<LayoutProfil/>}>
        <Route path={"/"} element={<Navigate to={"to_rise"}/>}/>
        <Route path={"to_rise"} element={<ToRiseProfil/>}/>
        <Route path={"formations"} element={<FormationsProfil/>}/>
        <Route path={"*"} element={<NotFound/>}/>
      </Route>
    </Routes>
  )
}
