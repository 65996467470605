import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import * as React from "react";

import {MyActivite} from "../CompetencesInterfaces";
import {OneActiviteR} from "./OneActiviteR";


interface ListActivitesRCtrlProps{
  className?:string;
  Activites:MyActivite[];
}

const ListActivitesRCtrl = (props:ListActivitesRCtrlProps)=>{
  const [open, setOpen] = useState(false);
  const SwitchOpen = ()=>{
    setOpen(o=>!o);
  }
  return (
    <div className={`list_actvites ${props.className}`}>
        <div className="wrap_legende">
          <div className="btn_legend" onClick={SwitchOpen}>légende</div>
          <div className={`content_legend ${open ? 'opened' : 'close'}`}>
            <div className="leg_nivo">
              <div className="numberNivo">1</div>
              <div className={"explain"}>Connaissance théorique, sans mise en pratique de l'activité</div>
            </div>
            <div className="leg_nivo">
              <div className="numberNivo">2</div>
              <div className={"explain"}>Mise en oeuvre en situation normale mais supervision nécessaire</div>
            </div>
            <div className="leg_nivo">
              <div className="numberNivo">3</div>
              <div className="explain">Mise en oeuvre autonome et capacité à régler les dysfonctionnements</div>
            </div>
            <div className="leg_nivo">
              <div className="numberNivo">4</div>
              <div className={"explain"}>Mise en oeuvre autonome y compris en situation complexe.</div>
            </div>
            <div className="leg_nivo">
              <div className="numberNivo">5</div>
              <div className="explain">Expert reconnu de l'activité</div>
            </div>
          </div>
        </div>
      <div className={"head"}>
        <div className="lib_item"></div>
        <div className="lib_nivo">1</div>
        <div className="lib_nivo">2</div>
        <div className="lib_nivo">3</div>
        <div className="lib_nivo">4</div>
        <div className="lib_nivo">5</div>

      </div>
      <div className={`wrap_list`}>
        <div className={`in_list`}>
          {props.Activites.sort((a, b) => a.libelle > b.libelle ? 1 : -1).map(acti=>(
                    <OneActiviteR Activite={acti} key={`OneActi${acti.id}`}/>
                  ))}
            </div>
        </div>
    </div>
  )
}

export const ListActivitesR = styled(ListActivitesRCtrl)`
  padding: 1% 2%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .btn_legend{
    background: ${props => props.theme.secondaryLighten};
    padding: 0.25rem 0.5rem;
    width: max-content;
    border-radius: 6px;
  }
  .content_legend{
    height: 0px;
    overflow: hidden;
    padding: 0;
    transition: height 0.3s;
    &.opened{
      padding: 0.8rem 0;
      height: 160px;
      transition: height 0.3s;
    }
  }
  .leg_nivo{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  .wrap_list{
    flex-grow: 1;
    overflow-y: scroll;
  }
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
  .head{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .lib_nivo{
    width: ${`${60/5}%`};
    text-align: center;
    font-size: 12px;
    padding: 0.5rem;
    border-right: dashed black 1px;
  }
  .lib_item{
    width: 40%;
  }
`
