import styled from "styled-components";
import {useGetEmployeeMe, useGetXp} from "@mbj-front-monorepo/data-access";
import {MbjButton, MbjLoading, MbjModalFullMobile, useModal} from "@mbj-front-monorepo/ui";
import {CvXpEmployeeCard} from "./CvXpEmployeeCard";
import {GoPlus} from "react-icons/go";
import * as React from "react";
import {MbjEmployeeXpNewForm} from "@mbj-front-monorepo/data-ui";

interface CvEmployeeCtrlProps {
  className?:string;
}

const CvEmployeeCtrl = ({className}: CvEmployeeCtrlProps) => {
  const EmployeeQuery = useGetEmployeeMe();
  const EmployeeXPQuery = useGetXp(EmployeeQuery.data?.id)
  const {isShowing, toggle} = useModal()
  return (
    <div className={`cvEmp ${className}`}>
      {EmployeeQuery.isLoading || EmployeeXPQuery.isLoading ?
        <MbjLoading/>:
        <div className="contentCV">
          <div className="wrap_btn">
            <MbjButton leftIcon={<GoPlus />} onClick={toggle} alignSelf={"flex-end"}>
              Ajouter une expérience
            </MbjButton>
          </div>
          {EmployeeXPQuery.data?.map((item, idx)=>(
            <CvXpEmployeeCard EmployeeXp={item} key={`oneXp${idx}`}/>
          ))}
        </div>
      }
      <MbjModalFullMobile open={isShowing} closeMe={toggle} titre={"Ajouter une expérience"}>
        <div className="wrap_form" style={{padding:"1rem"}}>
          <MbjEmployeeXpNewForm idEmployee={EmployeeQuery.data?.id||0}/>
        </div>
      </MbjModalFullMobile>
    </div>
  )
}

export const CvEmployee = styled(CvEmployeeCtrl)`
  height: 100%;
  .contentCV{
    overflow-y: auto;
    padding: 20px 0;
  }
  .wrap_btn{
    display: flex;
    justify-content: flex-end;
    padding: 0 0.2rem;
  }

`
