import {Navigate, Route, Routes} from "react-router-dom";
import {CvMain} from "./CvMain";
import {NotFound} from "../../components/NotFound/NotFound";
import {CvMainFi} from "./CvMainFI";
import {LayoutCv} from "./components/LayoutCv";


export const CvRooter = ()=>{
  return (
    <Routes>
      <Route element={<LayoutCv/>}>
        <Route path={"/"} element={<Navigate to={"fi"}/>}/>
        <Route path={"xp"} element={<CvMain/>}/>
        <Route path={"fi"} element={<CvMainFi/>}/>
        <Route path={"*"} element={<NotFound/>}/>
      </Route>
    </Routes>
  )
}
