import styled from "styled-components";
import {useGetParticulierMe} from "@mbj-front-monorepo/data-access";
import {MbjModalFullMobile, useModal} from "@mbj-front-monorepo/ui";
import {useState} from "react";
import {AllMobExterneEmpl} from "../Employee/AllMobExterneEmpl";
import {RecommandationMbj} from "../Employee/RecommandationMbj";
import {CompareToMetier} from "../components/CompareToMetier";
import {AllMobExternePart} from "./AllMobExternePart";
import {RecommandationMbjPart} from "./RecommandationMbjPart";
import {CompareToMetierPart} from "../components/CompareToMetierPart";

interface MobiliteExternePartCtrlProps {
  className?:string;
}

const MobiliteExternePartCtrl = ({className}: MobiliteExternePartCtrlProps) => {
  const ParticulierMe = useGetParticulierMe();
  const {isShowing, toggle} = useModal()
  const [idMetierCurrent, setidMetierCurrent] = useState<number>(0)
  const SetMetCurr = (id:number)=>{
    setidMetierCurrent(id)
    toggle();
  }
  return (
    <div className={`mob_ext_part ${className}`}>
      <div className="block_mob">
        <div className="titre_block">Toutes les mobilités</div>
        <AllMobExternePart setIdMetier={SetMetCurr}/>
      </div>
      <div className="block_mob">
        <div className="titre_block">Recommandé par mbj</div>
        <RecommandationMbjPart setIdMetier={SetMetCurr}/>
      </div>
      <MbjModalFullMobile open={isShowing} closeMe={toggle} titre={"Comparaison"}>
        <CompareToMetierPart idPart={ParticulierMe.data?.id||0}  idMetier={idMetierCurrent}/>
      </MbjModalFullMobile>
    </div>
  )
}

export const MobiliteExternePart = styled(MobiliteExternePartCtrl)`
  padding: 1rem 0.25rem 0.5rem;
  height: 625px;
  overflow-y: auto;
  .block_mob{
    background: white;
    padding: 0.5rem;
    margin-bottom: 10px;
    .titre_block{
      font-size: 16px;
      color: ${props => props.theme.primary};
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
`
