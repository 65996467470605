import styled from "styled-components";
import {useGetEmployee, useGetEmployeeMe, useGetProximiteReferentiel} from "@mbj-front-monorepo/data-access";
import React, {useMemo} from "react";
import {MbjCompareActiMobile, MbjLoading} from "@mbj-front-monorepo/ui";

interface ToRiseProfilCtrlProps {
  className?:string;
}
interface OneLineLearn{
  id:number,
  libelle:string,
  niveau:number,
  niveauCible:number
}
const ToRiseProfilCtrl = ({className}: ToRiseProfilCtrlProps) => {
  const EmployeeQuery = useGetEmployeeMe();
  const EmployeeQueryTotal = useGetEmployee(EmployeeQuery.data?.id||undefined)
  const useGetMobBilanQuery = useGetProximiteReferentiel("EUM", EmployeeQuery.data?.id, EmployeeQueryTotal.data?.UserMetier?.id);
  const listToLearn = useMemo(()=>{
    if(!useGetMobBilanQuery.data){
      return []
    } else {
      const myList:OneLineLearn[] = [];
      if(useGetMobBilanQuery.data.Missed){
        useGetMobBilanQuery.data.Missed.map(l=>{
          myList.push({
            id:l.id || 0,
            libelle:l.libelle || '',
            niveau:l.niveauActuel || 0,
            niveauCible:l.niveauAttendu || 0
          })
        })
      }
      if(useGetMobBilanQuery.data.ToRised){
        useGetMobBilanQuery.data.ToRised.map(l=>{
          myList.push({
            id:l.id || 0,
            libelle:l.libelle || '',
            niveau:l.niveauActuel || 0,
            niveauCible:l.niveauAttendu || 0
          })
        })
      }
      console.log(myList);
      return myList;
    }
  }, [useGetMobBilanQuery.data])
  return (
    <div className={`to_rise ${className}`}>
      {EmployeeQuery.isLoading || EmployeeQueryTotal.isLoading || useGetMobBilanQuery.isLoading ?
        <MbjLoading/>:
        <>
          <p style={{width: "100%", marginBottom: "15px"}} className={`introduction`}>Afin de mieux maîtriser les
            compétences attendues, selon My-Better-Job, vous devriez améliorer les compétences
            suivantes
            :</p>
          <MbjCompareActiMobile colorNiveau={"primary"} colorNiveauCible={"secondary"} listActi={listToLearn.map(l=> {
            return {id:l.id, libelle:l.libelle, niveau:l.niveau, niveauCible:l.niveauCible}
          })}/>
        </>
      }

    </div>
  )
}

export const ToRiseProfil = styled(ToRiseProfilCtrl)`
  padding: 1rem 0.5rem;
`
