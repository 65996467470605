import styled from "styled-components";
import {getDateFR, GetElementType} from "@mbj-front-monorepo/utils";
import {Operation} from "@mbj-front-monorepo/data-access";
import React from "react";
import {MbjStaticInfo} from "@mbj-front-monorepo/ui";

interface CvXpEmployeeCardCtrlProps {
  className?:string;
  EmployeeXp: GetElementType<Operation<"getEmployeeXpCollection">>;
}

const CvXpEmployeeCardCtrl = ({className, EmployeeXp}: CvXpEmployeeCardCtrlProps) => {
  return (
    <div className={`CvEmployeeCvCard ${className}`}>
      <div className="HeadCard">
        <div className="dates">
          Du {EmployeeXp.startAt ? getDateFR(new Date(EmployeeXp.startAt)) : "inconnu"} au{" "}
          {EmployeeXp.endAt ? getDateFR(new Date(EmployeeXp.endAt)) : "maintenant"}
        </div>
        <div className={`name_xp`}>{EmployeeXp.Metier?.libelle}</div>
      </div>
      <div className="BodyCard">
        <MbjStaticInfo
          label="Type de contrat : "
          info={EmployeeXp.TypeContrat ? EmployeeXp.TypeContrat.libelle : "inconnu"}
          direction={"row"}
          gap={"5px"}
        />
        <MbjStaticInfo
          label="Salaire : "
          info={EmployeeXp.salary ? EmployeeXp.salary + "€" : "inconnu"}
          direction={"row"}
          gap={"5px"}
        />
        {EmployeeXp.description && (
          <MbjStaticInfo label="Description : " info={EmployeeXp.description} gap={"5px"} />
        )}
      </div>
    </div>
  )
}

export const CvXpEmployeeCard = styled(CvXpEmployeeCardCtrl)`
  background: white;
  margin: 10px 0;
    .HeadCard{
      padding: 0.5rem 0.5rem;
      .dates{
        font-weight: bold;
      }
      .name_xp{
        color: ${props=>props.theme.primary};
        font-weight: bold;
      }
    }
  .BodyCard{
    padding: 0.25rem 0.75rem;
  }
`
