import styled from "styled-components";
import React from "react";

interface CoordOFMobileCtrlProps {
  className?:string;
  titre:string;
  infos:React.ReactNode;
}

const CoordOFMobileCtrl = (props: CoordOFMobileCtrlProps) => {
  return (
    <div className={`line_coord ${props.className}`}>
      <div className={`titre`}>{props.titre}</div>
      <div className={`infos`}>{props.infos}</div>
    </div>
  )
}

export const CoordOFMobile = styled(CoordOFMobileCtrl)`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  .link_mail{
    &:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }
`
