import React from "react";
import styled from "styled-components";
import {
  MbjCard,
  MbjCardBody,
  MbjCardCtrlProps,
  MbjCardFooter,
  MbjCardHeader,
  MbjConfirmModal,
  MbjFlex,
  MbjHeading,
  MbjIconButton,
  MbjModal,
  MbjStaticInfo,
  useModal,
} from "@mbj-front-monorepo/ui";
import { Operation, useDeleteFi } from "@mbj-front-monorepo/data-access";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import { MbjEmployeeFiEditForm } from "./MbjEmployeeFiEditForm";
import { BiEdit } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";

interface MbjEmployeeFiCardCtrlProps extends MbjCardCtrlProps {
  EmployeeFi: GetElementType<Operation<"getEmployeeFICollection">>;
  isReadOnly?:boolean;
}
const MbjEmployeeFiCardCtrl = (props: MbjEmployeeFiCardCtrlProps) => {
  const { isShowing, toggle } = useModal();
  const { isShowing: ShowConfirm, toggle: toggleConfirm } = useModal();
  const mutationDelete = useDeleteFi();
  const HandleConfirmDelete = () => {
    mutationDelete.mutate(props.EmployeeFi.id || -1, {
      onSettled: () => {
        toggleConfirm();
      },
    });
  };
  return (
    <>
      <MbjCard {...props}>
        <MbjCardHeader>
          <MbjHeading>
            {props.EmployeeFi.obtentionAt &&
              <span style={{display:"inline-block", width:"170px", flexShrink:0}}>Obtenu le {new Date(props.EmployeeFi.obtentionAt.slice(0,10)+" 12:00:00").toLocaleDateString()} :{" "}</span>
            }
            {props.EmployeeFi.nom}
          </MbjHeading>
        </MbjCardHeader>
        <MbjCardBody>
          <MbjFlex direction={"column"}>
            <MbjFlex gap={"15px"}>
              <MbjStaticInfo
                label="Niveau d'étude : "
                info={props.EmployeeFi.NiveauEtude ? props.EmployeeFi.NiveauEtude.libelle : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
              <MbjStaticInfo
                label="Discipline : "
                info={props.EmployeeFi.Discipline ? props.EmployeeFi.Discipline.libelle : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
            </MbjFlex>
            {props.EmployeeFi.description && (
              <MbjStaticInfo label="Description : " info={props.EmployeeFi.description} gap={"5px"} />
            )}
          </MbjFlex>
        </MbjCardBody>
        {!props.isReadOnly &&
        <MbjCardFooter>
          <MbjFlex direction={"row"} justify={"flex-end"} gap={"7px"}>
            <MbjIconButton
                size={"sm"}
              onClick={toggle}
              ariaLabel={"Editer la formation"}
              icon={<BiEdit />}
              themeColor={"secondary"}
              isRound={false}
            />
            <MbjIconButton
                size={"sm"}
              onClick={toggleConfirm}
              ariaLabel={"Supprimer la formation"}
              icon={<BsTrash />}
              themeColor={"warning"}
              isRound={false}
            />
          </MbjFlex>
        </MbjCardFooter>
        }
      </MbjCard>
      <MbjModal isShowing={isShowing} hide={toggle} title="Modification d'une formation initiale">
        <MbjEmployeeFiEditForm EmployeeFi={props.EmployeeFi} hideModal={toggle} />
      </MbjModal>
      <MbjConfirmModal
        isShowing={ShowConfirm}
        hide={toggleConfirm}
        title="Confirmation suppression formation"
        confirmText={"Voulez-vous vraiment supprimer cette formation ?"}
        confirmFunction={HandleConfirmDelete}
        isLoading={mutationDelete.isLoading}
      />
    </>
  );
};

export const MbjEmployeeFiCard = styled(MbjEmployeeFiCardCtrl)((props) => ({}));
