import {Navigate, Route, Routes} from "react-router-dom";
import {NotFound} from "../../components/NotFound/NotFound";

import {ProfilMain} from "./ProfilMain";


export const ProfilRooter = ()=>{
  return (
    <Routes>
      <Route path={"/"} element={<Navigate to={"main"}/>}/>
      <Route path={"main/*"} element={<ProfilMain/>}/>
      <Route path={"*"} element={<NotFound/>}/>
    </Routes>
  )
}
