import styled from "styled-components";
import React from "react";

interface BlockDashMobileCtrlProps {
  className?:string;
  titre:string;
  children:React.ReactNode
}

const BlockDashMobileCtrl = ({className, titre, children}: BlockDashMobileCtrlProps) => {
  return (
    <div className={`card_dash ${className}`}>
      <div className="titre_dash">{titre}</div>
      <div className="bodyCard">
        {children}
      </div>
    </div>
  )
}

export const BlockDashMobile = styled(BlockDashMobileCtrl)`
  width: 100%;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  .titre_dash{
    padding: 0.25rem 0.5rem;
    font-weight: bold;
  }
  bodyCard{
    flex-grow: 1;
  }
`
