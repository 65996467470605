import styled from "styled-components";
import {DashParticulier} from "./DashParticulier";
import {DashEmployee} from "./DashEmployee";

interface DashboardCtrlProps {
  className?:string;
}

const DashboardCtrl = (props: DashboardCtrlProps) => {

  const role = localStorage.getItem("__user_role__");
  return (
    <div className={`dash_part ${props.className}`}>
      {role === 'particulier' ?
        <DashParticulier/> : <DashEmployee/>
      }
    </div>
  )
}

export const Dashboard = styled(DashboardCtrl)`
  height: 100%;
`
