import styled from "styled-components";
import {MyActivite} from "../CompetencesInterfaces";
import {components, useAddPropositionNiveau, useGetPropositionNiveauByEmp} from "@mbj-front-monorepo/data-access";
import React, {useEffect, useState} from "react";
import {MbjButton, MbjLoading} from "@mbj-front-monorepo/ui";

interface ModalPropositionNiveauCtrlProps {
  className?:string;
  Activite:MyActivite;
  idEmp:number;
  closeMe:()=>void;
}

interface MyLeg{
  nivo:number;
  libelle:string;
}
const TabLegende:MyLeg[] = [
  {nivo:0, libelle:"Aucun"},
  {nivo:1, libelle:"Connaissance théorique, sans mise en pratique de l'activité"},
  {nivo:2, libelle:"Mise en oeuvre en situation normale mais supervision nécessaire"},
  {nivo:3, libelle:"Mise en oeuvre autonome et capacité à régler les dysfonctionnements"},
  {nivo:4, libelle:"Mise en oeuvre autonome y compris en situation complexe."},
  {nivo:5, libelle:"Expert reconnu de l'activité"},
]

const ModalPropositionNiveauCtrl = ({className, Activite, idEmp, closeMe}: ModalPropositionNiveauCtrlProps) => {
  const PropoQuery = useGetPropositionNiveauByEmp(idEmp);
  const mutation = useAddPropositionNiveau();
  const [niveauPropo, setNiveauPropo] = useState(Activite.Niveau);
  const [legendeCurr, setLegende] = useState((TabLegende.find(l=>l.nivo === Activite.Niveau) as MyLeg).libelle)
  const [PropoCurr, setPropoCurr] = useState<components["schemas"]["PropositionNiveau-read.propo_read.Employee.simple_read.Activite"]|null>(null)
  const [errorSave, setErrorSave] = useState('')
  useEffect(() => {
    if(PropoQuery.data){
      const PropoFind = PropoQuery.data.find(p=>p.Activite?.id === Activite.id)
      if(PropoFind){
        setPropoCurr(PropoFind)
      } else {
        setPropoCurr(null)
      }
    }
  }, [PropoQuery.data, Activite.id]);
  const ChangeNiveauPropo = (nivo:number)=>{
    const myLeg = TabLegende.find(l=>l.nivo === nivo) as MyLeg;
    setNiveauPropo(nivo);
    setLegende(myLeg.libelle);
  }
  const getStepNiveau = ()=>{
    const TabStep:React.ReactNode[] = [];
    for(let i=1;i<=5;i++){

      TabStep.push(
        <div className={`one_step ${i<=niveauPropo ? 'hight' : 'upper'}`} key={`S${i}`} onClick={()=>ChangeNiveauPropo(i)}/>
      )
    }
    return TabStep;
  }
  const Submit = ()=>{
    const datas:any = {
      Employee:`/api/employees/${idEmp}`,
      Activite:`/api/activites/${Activite.id||0}`,
      niveauActuel:Activite.Niveau,
      niveauPropose:niveauPropo
    }
    mutation.mutate(datas, {
      onSuccess:()=>{
        closeMe();
      }, onError:()=>{
        setErrorSave('une erreur est survenue, merci de retenter plus tard');
      }
    })
  }
  return (
    <div className={`propo_niveau ${className}`}>
      {PropoQuery.isLoading ?
      <MbjLoading/>:
        PropoCurr ?
        <div className={"already_wrap"}>
          <p className="explain_not">Vous avez déjà initié une demande pour cette activité le {new Date((PropoCurr.creationAt as string)).toLocaleDateString()}, pour un niveau de {PropoCurr.niveauPropose}</p>
        </div>:
          <>
            <p className={"explain"}>Vous pouvez faire une demande de changement de niveau auprès de votre responsable. Ce dernier pourra l'accepter et attester de ce niveau.</p>
            <div className="wrapper_niveau">
              <div className="wrap_one_niveau">
                <div className="titre">Niveau actuel ({Activite.Niveau})</div>
                <div className="wrapper_niveau_slider">
                  <div className="slidy_niveau" style={{width:`${(Activite.Niveau/5)*100}%`}}></div>
                </div>
                <div className="legendeNivo">
                  {(TabLegende.find(l=>l.nivo === Activite.Niveau) as MyLeg).libelle}
                </div>
              </div>
              <div className="wrap_one_niveau">
                <div className="titre">Votre proposition ({niveauPropo})</div>
                <div className={"wrapper_choix_niveau"}>
                  {getStepNiveau().map((item) => item)}
                </div>
                <div className="legendeNivo">
                  {legendeCurr}
                </div>
              </div>
            </div>
            {niveauPropo < Activite.Niveau &&
              <div className="carefull">Attention le niveau choisi est inférieure au niveau de départ !</div>
            }
            {errorSave !== '' &&
              <div className="errorSave">Une erreur est survenue, merci de retenter plus tard !</div>
            }
            {niveauPropo !== Activite.Niveau &&
              <div className="wrap_sender">
                <MbjButton themeColor={"primary"} onClick={Submit} size={"sm"}>Faire la demande de niveau</MbjButton>
              </div>
            }
          </>
      }
    </div>
  )
}

export const ModalPropositionNiveau = styled(ModalPropositionNiveauCtrl)`
  padding: 1rem;
  .errorSave{
    margin: 20px 0;
    background: ${props => props.theme.warningLighten};
    padding: 1rem;
    color:white;
    text-align: center;
  }
    .wrap_sender{
      display: flex;
      justify-content: flex-end;
    }
  .explain{
    font-size: 17px;
    margin-bottom: 25px;
  }
  .wrap_one_niveau{
    margin-bottom: 15px;
    .titre{
      font-weight: bold;
    }
  }
  .wrapper_choix_niveau{
    width: 100%;
    position: relative;
    height: 10px;
    border-radius: 6px;
    background: #ccc;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    &:hover{
      cursor: pointer;
    }
    .one_step{

      width: 20%;
      height: 100%;
      &.hight{
        background: ${props => props.theme.third};
      }
    }
  }
  .wrapper_niveau_slider{
    width: 100%;
    position: relative;
    height: 10px;
    border-radius: 6px;
    background: #ccc;
    overflow: hidden;
    .slidy_niveau{
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      background: ${props => props.theme.third};
    }
  }
`
