import styled from "styled-components";
import {useGetEmployee, useGetEmployeeMe} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {useEffect, useState} from "react";
import {BlockDashMobile} from "./components/BlockDashMobile";
import {EmployeeMemberCard} from "./components/EmployeeMemberCard";

interface DashEmployeeCtrlProps {
  className?:string;
}

const DashEmployeeCtrl = (props: DashEmployeeCtrlProps) => {
  const EmplQuery = useGetEmployeeMe()
  const [idEmp, setIdEmp] = useState<number|undefined>(undefined)
  const EmployeeQuery = useGetEmployee(idEmp);
  useEffect(() => {
    if(EmplQuery.data && EmplQuery.data.id){
      setIdEmp(EmplQuery.data.id)
    }
  }, [EmplQuery.data]);
  return (
    <div className={`empl ${props.className} pageStdMobile`}>
      {EmplQuery.isLoading ? <MbjLoading/> :
        <div className={`content_dash`}>
          <h2>Bienvenue {EmplQuery.data?.prenom}</h2>
          <div className="content_dash">
            <BlockDashMobile titre={"Mon entreprise"}>
              <EmployeeMemberCard idMember={EmployeeQuery.data?.MemberMaster?.id||undefined}/>
            </BlockDashMobile>
          </div>
        </div>
      }
    </div>
  )
}

export const DashEmployee = styled(DashEmployeeCtrl)`
    padding: 2%;
  h2{
    font-size: 26px;
    text-align: center;
  }
  .content_dash{
    padding: 30px 0;
  }
`
