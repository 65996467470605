import styled from "styled-components";
import {useGetParticulier, useGetParticulierMe} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";

interface DashParticulierCtrlProps {
  className?:string;
}

const DashParticulierCtrl = (props: DashParticulierCtrlProps) => {
  const PartQuery = useGetParticulierMe()
  return (
    <div className={`particulier ${props.className}`}>
      {PartQuery.isLoading ? <MbjLoading/> :
        <>
          <h1>Bonjour {PartQuery.data?.prenom}</h1>
        </>
      }
    </div>
  )
}

export const DashParticulier = styled(DashParticulierCtrl)`

`
