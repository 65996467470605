import { fetchApi, Operation } from "../../services/fetchApi";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";


export const useGetPropositionNiveauByEmp = (idEmp:number)=>{
  const promise = () => fetchApi("/api/proposition_niveaus", { method: "get", query: { Employee: idEmp + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["propostionniveau", idEmp], promise);
}

export const useAddPropositionNiveau = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postPropositionNiveauCollection", "requestBody">) => {
      console.log(data);
      return fetchApi("/api/proposition_niveaus", {
        method: "post",
        json: {...data},
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["propostionniveau"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}
