import styled from "styled-components";
import {useGetProximiteReferentiel, useGetUserMetier, useGetUserMetiers} from "@mbj-front-monorepo/data-access";
import {MbjCirclePercent, MbjLoading, MbjSquareChart} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {FiChevronDown} from "react-icons/fi";
import {useState} from "react";
import {CompetenceDetails} from "../../components/CompetenceDetails";

interface CompareToUserMetierCtrlProps {
  className?:string;
  idUserMetier:number;
  idEmployee:number;
}

const CompareToUserMetierCtrl = ({idUserMetier, className, idEmployee}: CompareToUserMetierCtrlProps) => {
  const proximiteReferentielQuery = useGetProximiteReferentiel('EUM', idEmployee, idUserMetier);
  const UserMetierQuery = useGetUserMetier(idUserMetier);
  const [openDetails, setOpenDetails] = useState(false);
  const totalComp: number =
    (proximiteReferentielQuery.data?.Shared?.length || 0) +
    (proximiteReferentielQuery.data?.Missed?.length || 0) +
    (proximiteReferentielQuery.data?.ToRised?.length || 0);
  return (
    <div className={`compare_user_metier ${className}`}>
      {proximiteReferentielQuery.isLoading || UserMetierQuery.isLoading ?
        <MbjLoading/>:
        <>
          <h1 style={{padding: "0.5rem", textAlign: "center"}}>Comparaison au
            métier {UserMetierQuery.data?.namePerso || 'nc'}</h1>
          <MbjCirclePercent
            percent={
              proximiteReferentielQuery.data?.score ? Math.round(proximiteReferentielQuery.data?.score * 100) : 0
            }
          />
          <div className="chartsContainer">
            <MbjSquareChart
              number={proximiteReferentielQuery.data?.Shared?.length || 0}
              total={totalComp}
              legend={"compétences partagées"}
              color={"#85c172"}
            />

            <MbjSquareChart
              number={proximiteReferentielQuery.data?.ToRised?.length || 0}
              total={totalComp}
              legend={"compétences à améliorer"}
              color={"#eeac6b"}
            />
            <MbjSquareChart
              number={proximiteReferentielQuery.data?.Missed?.length || 0}
              total={totalComp}
              legend={"compétences à acquérir"}
              color={"#ee6b7d"}
            />
          </div>
          <div className={`switch_details ${openDetails ? 'opened' : 'closed'}`}
               onClick={() => setOpenDetails(o => !o)}>
            <div className="wrap_svg_switch">
              <FiChevronDown/>
            </div>
            <span>Afficher le détail</span>
            <div className="wrap_svg_switch">
              <FiChevronDown/>
            </div>
          </div>
          {openDetails &&
            <div className="content_details">
              <CompetenceDetails mainColor={"#b4eca4"} libelle={"Partagées"}
                                 List={proximiteReferentielQuery.data?.Shared}/>
              <CompetenceDetails mainColor={"#b4eca4"} libelle={"A améliorer"}
                                 List={proximiteReferentielQuery.data?.ToRised}/>
              <CompetenceDetails mainColor={"#b4eca4"} libelle={"A acquérir"}
                                 List={proximiteReferentielQuery.data?.Missed}/>
            </div>
          }
        </>
      }
    </div>
  )
}

export const CompareToUserMetier = styled(CompareToUserMetierCtrl)`
  .chartsContainer {
    margin: 15px 0;
    padding: 0 0.5rem;
  }
  .wrap_svg_switch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: rotate(0);
    transition: transform 0.3s;
  }

  .switch_details {
    width: 95%;
    padding: 0.5rem 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    gap: 5px;
    margin: auto;
    align-items: center;
    background: ${props => props.theme.secondaryLighten};
    &.opened{
      .wrap_svg_switch{
        transform: rotate(180deg);
        transition: transform 0.3s;
      }
    }
  }
`
