import styled from "styled-components";
import {useGetMember} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import React from "react";

interface EmployeeMemberCardCtrlProps {
  className?:string;
  idMember:number|undefined,
}

const EmployeeMemberCardCtrl = ({className, idMember}: EmployeeMemberCardCtrlProps) => {
  const MemberQuery = useGetMember(idMember)
  return (
    <div className={`member_card ${className}`}>
      {MemberQuery.isLoading ?
        <MbjLoading/>:
        <div className="relate_member">
          {MemberQuery.data?.logoUrl &&
            <div className="NamePlace">
              <div className="wrap_log">
                <img src={`${process.env.REACT_APP_API_URL_ROOT}${MemberQuery.data.logoUrl}`}/>
              </div>
              <div className="name_entreprise">{MemberQuery.data?.name}</div>
            </div>
          }
          <div className="line_info">
            <div className="libInfo">Adresse</div>
            <div className="Info">{MemberQuery.data?.adresse||''} {MemberQuery.data?.codePostal||''} {MemberQuery.data?.ville||''}</div>
          </div>
        </div>
      }
    </div>
  )
}

export const EmployeeMemberCard = styled(EmployeeMemberCardCtrl)`
  padding: 1rem;
  .NamePlace{
    display: flex;
    justify-content: flex-start;
    gap:12px;
    align-items: center;
  }
  .line_info{
    margin-bottom: 10;
    .libInfo{
      font-weight: bold;
    }
  }
  .wrap_log{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    img{
      width: 100%;

    }
  }
`
